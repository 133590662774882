import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import Base from '../../_config/base';

class Cardslider extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.cardsliderSwiper');

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation],
      centeredSlides: false,
      slidesPerView: 1,
      spaceBetween: 24,
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        426: {
          direction: 'horizontal',
        },
        768: {
          direction: 'horizontal',
          slidesPerView: 2,
        },
        1024: {
          direction: 'horizontal',
          slidesPerView: 3,
        },
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Cardslider.className = 'Cardslider';
export default Cardslider;
