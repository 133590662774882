/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import Base from '../../_config/base';

class Hero extends Base {
  constructor(el) {
    super(el);
    const videoWrapper = el.querySelector('.hero__video');
    const video = el.querySelector('.hero__video__video');
    const playButton = el.querySelector('.hero__video__play');

    if (video) {
      playButton.onclick = () => {
        if (video.paused) {
          playButton.classList.add('d-none');

          setTimeout(() => {
            video.setAttribute('controls', 'controls');
            video.play();
          }, 10);
        } else {
          video.pause();
          playButton.classList.remove('d-none');

          setTimeout(() => {
            video.removeAttribute('controls');
          }, 10);
        }
      };
    }
    // onPageNav Ping - developed but not in use

    // const onPageNav = el.querySelector('.hero__onpagenav');

    // let lastScrollY = window.pageYOffset;
    // const oldValue = 0;
    // const navOffsetTop = onPageNav.offsetTop; // Get nav's initial position

    // window.addEventListener('scroll', () => {
    //   const currentScrollY = window.pageYOffset;

    //   if (lastScrollY < navOffsetTop) {
    //     // Near the top → Remove pinned class
    //     onPageNav.classList.remove('hero__onpagenav--pinned');
    //   } else if (currentScrollY > navOffsetTop) {
    //     // If scrolled past the nav position
    //     if (oldValue - currentScrollY > 0) {
    //       // up
    //       onPageNav.classList.remove('hero__onpagenav--pinned');
    //     } else if (oldValue - (currentScrollY + 100) < 0) {
    //       // down
    //       onPageNav.classList.add('hero__onpagenav--pinned');
    //     }
    //   }

    //   lastScrollY = currentScrollY; // Update last scroll position
    // });
  }
}

Hero.className = 'Hero';
export default Hero;
