import Base from '../../_config/base';

class Search extends Base {
  constructor(el) {
    super(el);
    const searchInput = el.querySelector('.search__form__input');
    const searchClose = el.querySelector('.search__close');
    const skeleton = el.querySelector('.search__skeloton');
    const lastSearch = el.querySelector('.search__lastsearch');
    const body = document.getElementById('body');

    searchClose.onclick = () => {
      body.classList.remove('overflow-hidden');
      document.querySelector('.search').classList.toggle('search--closed');
    };

    const addEventListenerToResults = () => {
      const searchResults = el.querySelectorAll('.search__results__entry');
      searchResults.forEach((result) => {
        result.onclick = () => {
          // add result.text to localStorage
          const lastSearches = JSON.parse(localStorage.getItem('lastSearches')) || [];
          const newSearch = { title: result.querySelector('h3').innerHTML, url: result.href };
          const existingIndex = lastSearches.findIndex((search) => search.url === newSearch.url);
          if (existingIndex !== -1) {
            lastSearches.splice(existingIndex, 1);
          }
          lastSearches.push(newSearch);
          localStorage.setItem('lastSearches', JSON.stringify(lastSearches));
        };
      });
    };

    // get last searches from localStorage
    const lastSearches = JSON.parse(localStorage.getItem('lastSearches')) || [];
    if (lastSearches.length > 0) {
      lastSearch.classList.remove('d-none');
      const lastSearchesWrapper = el.querySelector('.search__lastsearch__wrapper');
      // if more than 5 searches, remove all but the latest 5
      let searchesToDisplay = lastSearches;
      if (lastSearches.length > 5) {
        searchesToDisplay = lastSearches.slice(-5);
      }
      searchesToDisplay.forEach((search) => {
        const searchEl = document.createElement('a');
        searchEl.innerHTML = search.title;
        searchEl.href = search.url;
        searchEl.classList.add('text-primary');
        lastSearchesWrapper.appendChild(searchEl);
      });
    } else {
      lastSearch.classList.add('d-none');
    }

    const skeletonAnim = () => {
      skeleton.classList.remove('d-none');
      el.querySelector('.search__results').classList.add('d-none');
      const randomTimeout = Math.floor(Math.random() * (2000 - 800 + 1)) + 800;
      setTimeout(() => {
        skeleton.classList.add('d-none');
        el.querySelector('.search__results').classList.remove('d-none');
        addEventListenerToResults();
      }, randomTimeout);
    };

    // if input has character inside, show close button
    searchInput.oninput = () => {
      if (searchInput.value.length > 0) {
        el.querySelector('.search__form__abandonicon').classList.add('search__form__abandonicon--active');
        lastSearch.classList.add('d-none');
        skeletonAnim();
      } else {
        el.querySelector('.search__form__abandonicon').classList.remove('search__form__abandonicon--active');
        if (lastSearches.length > 0) {
          lastSearch.classList.remove('d-none');
        }
        el.querySelector('.search__results').classList.add('d-none');
        skeleton.classList.add('d-none');
      }
    };

    el.querySelector('.search__form__abandonicon').onclick = () => {
      searchInput.value = '';
      el.querySelector('.search__form__abandonicon').classList.remove('search__form__abandonicon--active');
    };
  }
}

Search.className = 'Search';
export default Search;
