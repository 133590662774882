import Button from './01-atoms/button/button';
import Teamgrid from './02-molecules/teamgrid/teamgrid';
import Awards from './02-molecules/awards/awards';
import Cardslider from './02-molecules/cardslider/cardslider';
import History from './02-molecules/history/history';
import Hero from './02-molecules/hero/hero';
import Numbers from './02-molecules/numbers/numbers';
import Iframe from './02-molecules/iframe/iframe';
import Header from './03-organisms/header/header';
import Search from './03-organisms/search/search';
import Footer from './03-organisms/footer/footer';
import Scrollsmoother from './03-organisms/globalanimations/scrollsmoother';
import Scrollinto from './03-organisms/globalanimations/scrollinto';

require('./main.scss');

function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  const html = document.querySelector('html');
  const Component = window.Streusel || {};

  html.classList.remove('no-js');

  Component.Teamgrid = Teamgrid.init();
  Component.Numbers = Numbers.init();
  Component.History = History.init();
  Component.Awards = Awards.init();
  Component.Cardslider = Cardslider.init();
  Component.Iframe = Iframe.init();
  Component.Button = Button.init();
  Component.Header = Header.init();
  Component.Hero = Hero.init();
  Component.Scrollsmoother = Scrollsmoother.init();
  Component.Search = Search.init();
  Component.Scrollinto = Scrollinto.init();
  Component.Footer = Footer.init();
});
