/* eslint-disable no-useless-constructor */
import Base from '../../_config/base';

class Iframe extends Base {
  constructor(el) {
    super(el);
    // const moreJobsBtn = el.querySelector('.iframe__button__job');
    // const iframe = el.querySelector('.iframe__iframe');

    // if (moreJobsBtn) {
    //   moreJobsBtn.onclick = () => {
    //     // get height of cross origin iframe and add 100vh
    //     const height = iframe.offsetHeight + window.innerHeight;
    //     iframe.style.height = `${height}px`;
    //   };
    // }
  }
}

Iframe.className = 'Iframe';
export default Iframe;
