import Base from '../../_config/base';

class Footer extends Base {
  constructor(el) {
    super(el);
    const helpfullOpenBtn = el.querySelector('.footer__helpfullinks__openbtn');

    helpfullOpenBtn.onclick = () => {
      el.querySelector('.footer__helpfullinks__content').classList.toggle('footer__helpfullinks__content--open');
      helpfullOpenBtn.classList.toggle('footer__helpfullinks__openbtn--open');
    };

    let ticking = false;
    const animateWhen = window.innerHeight - window.innerHeight / 10;

    function isInViewport(element) {
      const elementOffset = element.offsetTop;
      const windowOffset = window.pageYOffset;

      // add class if element is 33% visible
      if (windowOffset > elementOffset - animateWhen) {
        // animate
        element.classList.add('footer__animate');
      }
    }

    isInViewport(el);

    document.addEventListener('scroll', () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          isInViewport(el);
          ticking = false;
        });

        ticking = true;
      }
    });
  }
}

Footer.className = 'Footer';
export default Footer;
