import Base from '../../_config/base';

class Header extends Base {
  constructor(el) {
    super(el);
    this.hamburger = el.querySelector('.hamburger');
    this.parentItem = el.querySelector('.has-children > a');
    this.hamburgerMenu = el.querySelector('.hamburger-menu');
    this.logo = el.querySelector('.header__logo');
    const body = document.querySelector('body');
    const header = el;
    const searchOpen = [...el.querySelectorAll('.header__search')];

    this.hamburger.onclick = (e) => {
      e.preventDefault();
      this.hamburger.querySelector('#hamburger-icon').classList.toggle('active');
      this.logo.classList.toggle('header__logo--active');
      body.classList.toggle('overflow-hidden');
      el.classList.toggle('active');
      this.hamburgerMenu.classList.remove('active');
    };

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' && this.logo.classList.contains('header__logo--active')) {
        this.hamburger.querySelector('#hamburger-icon').classList.toggle('active');
        this.logo.classList.toggle('header__logo--active');
        body.classList.toggle('overflow-hidden');
        el.classList.toggle('active');
        this.hamburgerMenu.classList.remove('active');
      }
    });

    searchOpen.forEach((element) => {
      element.onclick = () => {
        body.classList.toggle('overflow-hidden');
        document.querySelector('.search').classList.toggle('search--closed');
        document.querySelector('.search__form__input').focus();
        this.hamburger.querySelector('#hamburger-icon').classList.toggle('active');
        this.logo.classList.toggle('header__logo--active');
        el.classList.toggle('active');
        this.hamburgerMenu.classList.remove('active');
      };
    });

    let oldValue = 0;
    window.addEventListener('scroll', () => {
      // Get the new Value
      const newValue = window.pageYOffset;

      // Subtract the two and conclude
      if (newValue < 5) {
        header.classList.remove('inactiveHeader');
        header.classList.remove('activeHeader');
      } else if (oldValue - newValue > 0) {
        // up
        header.classList.add('activeHeader');
        header.classList.remove('inactiveHeader');
      } else if (oldValue - newValue < 0) {
        // down
        header.classList.add('inactiveHeader');
        header.classList.remove('activeHeader');
        header.classList.add('activeHeader');
      }

      // Update the old value
      oldValue = newValue;
    });
  }
}

Header.className = 'Header';
export default Header;
