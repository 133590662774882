/* eslint-disable import/no-extraneous-dependencies */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import Base from '../../_config/base';

class Scrollsmoother extends Base {
  constructor(el) {
    super(el);
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    const mm = gsap.matchMedia();
    const anchors = [...el.querySelectorAll('.hero__onpagenav--a')];
    const anchorElements = [...el.querySelectorAll('.anchorElement')];

    const smoother = ScrollSmoother.create({
      smooth: 1.3,
      effects: true,
    });

    mm.add('(max-width: 769px)', () => {
      smoother.kill();
    });

    // Click behavior for navigation anchors
    anchors.forEach((anchor) => {
      anchor.onclick = () => {
        const anchorId = anchor.getAttribute('anchorid');
        anchorElements.forEach((anchorElement) => {
          if (anchorId === anchorElement.id) {
            smoother.scrollTo(`#${anchorElement.id}`, true, 'top 200px');
          }
        });
      };
    });

    // Check current URL for a hash and scroll if it matches an element
    const currentHash = window.location.hash;
    if (currentHash) {
      const hashId = currentHash.substring(1); // Remove the '#' character
      const targetElement = anchorElements.find((ele) => ele.id === hashId);
      if (targetElement) {
        smoother.scrollTo(`#${targetElement.id}`, true, 'top 200px');
      }
    }
  }
}

Scrollsmoother.className = 'Scrollsmoother';
export default Scrollsmoother;
