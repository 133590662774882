import Base from '../../_config/base';

class Numbers extends Base {
  constructor(el) {
    super(el);
    const counters = document.querySelectorAll('.numbers__number h2');

    const formatAnimatedNumber = (value, originalText) => {
      const hasPlus = originalText.trim().endsWith('+');

      return originalText
        .replace(/[\d\s,.]+/, value.toLocaleString('de-DE').replace(/\./g, ' '))
        .replace(/(\d)(Mio\.|Mia\.)/, '$1 $2') // Ensure space before "Mio." or "Mia."
        .replace(/\s\+/g, '') + (hasPlus ? ' ' : '');
    };

    const animateCounter = (element, endValue) => {
      let startValue = 0;
      const duration = 2000;
      const interval = 30;
      const step = endValue / (duration / interval);
      const originalText = element.innerText;

      const counter = setInterval(() => {
        startValue += step;
        if (startValue >= endValue) {
          startValue = endValue;
          clearInterval(counter);
        }

        const formattedValue = originalText.includes('Mio.') || originalText.includes('Mia.')
          ? startValue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/\./g, ',')
          : Math.round(startValue).toLocaleString('de-DE').replace(/\./g, ' ');

        element.innerText = formatAnimatedNumber(formattedValue, originalText);
      }, interval);
    };

    const observerOptions = {
      root: null,
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          const { target } = entry;
          const finalValue = parseFloat(target.getAttribute('number').replace(/\s/g, '').replace(',', '.'));

          // Introduce delay: Each counter starts 0.5s (500ms) after the previous one
          setTimeout(() => {
            animateCounter(target, finalValue);
            observer.unobserve(target);
          }, index * 100); // Delay increases with index (0ms, 500ms, 1000ms, ...)
        }
      });
    }, observerOptions);

    counters.forEach((counter) => observer.observe(counter));
  }
}

Numbers.className = 'Numbers';
export default Numbers;
