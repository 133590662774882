import Base from '../../_config/base';

class Teamgrid extends Base {
  constructor(el) {
    super(el);
    this.body = document.getElementById('body');
    const allBadeOpen = [...el.querySelectorAll('.teamgrid__item')];
    const allBadeClose = [...el.querySelectorAll('.popupClose')];
    const allPopups = [...el.querySelectorAll('.teamgrid__item__popup')];

    allBadeOpen.forEach((badge) => {
      badge.addEventListener('click', () => {
        if (el.querySelector('.teamgrid__item__badge') !== null) {
          this.body.classList.toggle('overflow-hidden');
          const teamid = badge.getAttribute('teamid');
          const correspondingPopup = allPopups.find((popup) => popup.getAttribute('teamid') === teamid);
          correspondingPopup.classList.toggle('teamgrid__item__popup--inactive');
        }
      });
    });

    allBadeClose.forEach((close) => {
      close.addEventListener('click', () => {
        this.body.classList.toggle('overflow-hidden');
        const teamid = close.getAttribute('teamid');
        const correspondingPopup = allPopups.find((popup) => popup.getAttribute('teamid') === teamid);
        correspondingPopup.classList.toggle('teamgrid__item__popup--inactive');
      });
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        allPopups.forEach((popups) => {
          popups.classList.add('teamgrid__item__popup--inactive');
          this.body.classList.remove('overflow-hidden');
        });
      }
    });
  }
}

Teamgrid.className = 'Teamgrid';
export default Teamgrid;
