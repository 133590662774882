import Base from '../../_config/base';

class History extends Base {
  constructor(el) {
    super(el);
    const moreBtn = el.querySelector('.history__more');
    const allChapters = [...el.querySelectorAll('.history__item__chapter')];
    let shownChapters = 7; // Initially shown items

    allChapters.sort((a, b) => Number(a.getAttribute('chapterid')) - Number(b.getAttribute('chapterid')));

    moreBtn.onclick = () => {
      const newShownCount = shownChapters + 7;

      // Show the next 7 hidden chapters
      allChapters.forEach((chapter, index) => {
        if (index < newShownCount) {
          chapter.classList.remove('history__item__chapter--hidden');
        }
      });

      shownChapters = newShownCount;

      // If all chapters are shown, hide the button
      if (shownChapters >= allChapters.length) {
        moreBtn.classList.add('d-none');
      }
    };
  }
}

History.className = 'History';
export default History;
