import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import Base from '../../_config/base';

class Awards extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.awardsSwiper');

    const sliderOpt = {
      // Use the Navigation module
      modules: [Navigation],
      centeredSlides: false,
      // Default configuration for mobile (<426px) uses vertical mode
      direction: 'vertical',
      slidesPerView: [...el.querySelectorAll('.awards__awards')].length,
      allowTouchMove: true,
      // These options allow the slider to release touch events
      // so that the page can scroll when the slider is at its edges.
      touchReleaseOnEdges: true,
      touchStartForcePreventDefault: false,
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // For devices 426px and above, use horizontal slider settings
        426: {
          direction: 'horizontal',
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        769: {
          direction: 'horizontal',
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
      },
    };

    // Initialize Swiper with the configured options
    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Awards.className = 'Awards';
export default Awards;
